.header {
  text-align: center;
}

.custom-btn-toolbar {
  display: inline !important;
}

.custom-btn-toolbar > .btn {
  margin-right: 1rem;
}
ul {
  list-style-type: none;
  padding: 0;
}

.menu ul {
  background-color: #222;
  margin: 0;
}

.menu li {
  font-family: sans-serif;
  font-size: 1.2em;
  line-height: 40px;
  height: 40px;
  border-bottom: 1px solid #888;
}
.App {
  background-color: #D3D3D3;
}
.Title{
  color: #000000;
  align-content: center;
  font-family: sans-serif;
  font-size: 2em;
  line-height: 40px;
  height: 40px;
  border-bottom: 2px dotted #888;
}
.menu a {
  text-decoration: none;
  color: #fff;
  display: block;
}
.App-link {
  color: #ffffff;
  align-content: center;
  font-family: sans-serif;
  font-size: 1.2em;
  line-height: 40px;
  height: 40px;
  border-bottom: 1px solid #888;
}
